import { gql } from 'urql'

const QueryTenantJobApplicants = gql`
  query (
    $page: Int
    $limit: Int
    $jobId: Int
    $jobStageId: Int
    $search: String
    $status: ApplicantStatus
  ) {
    jobApplicantsList(
      jobId: $jobId
      jobStageId: $jobStageId
      page: $page
      limit: $limit
      search: $search
      status: $status
    ) {
      collection {
        id
        createdAt
        updatedAt
        hiredDate
        profile {
          id
          fullName
          links
          email
          permittedFields
          profileCvs {
            attachments {
              id
              file
              blobs
            }
          }
          applicants {
            id
            jobId
            coverLetter
            job {
              status
              id
              title
              slug
              owner {
                id
                fullName
                email
              }
            }
            incoming
            createdBy {
              fullName
            }
          }
          applicableJobs {
            id
            title
            jobLocations {
              state
              country
            }
            owner {
              id
              fullName
              email
            }
            department {
              name
            }
          }
          avatarVariants
        }
        status
        jobStage {
          id
          stageLabel
          stageTypeId
          stageGroup
        }
        createdBy {
          email
          fullName
          avatarVariants
          defaultColour
        }
        flagNew
        lastestInterview {
          fromDatetime
          feedback {
            id
          }
          ikitFeedbacksSummary
        }
        nearestInterview {
          fromDatetime
          feedback {
            id
          }
          ikitFeedbacksSummary
        }
        job {
          id
          title
          company {
            contacts {
              firstName
              lastName
              email
            }
          }
        }
        placement {
          id
          status
          createdAt
          hiredDate
          onboardDate
          endOfProbationDate
          salary
          typeOfSalary
          currencyOfSalary
          fee
          typeOfFee
          revenue
          currencyOfRevenue
          applicant {
            id
            jobStage {
              id
            }
            createdAt
            hiredDate
          }
          profitSplits {
            id
            profitPercentage
            user {
              id
              fullName
              avatarVariants
              defaultColour
            }
          }
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTenantJobApplicants
